import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import UseTable from '../../modules/consume/presentation/UseTable';
import { OperationMode } from '../../modules/device/presentation/context/OperationMode';
import { InputTableMode } from '../../modules/device/interface/Device';
import { IOrderPadFormValue } from './OrderPadPage';
import UseAccount from '../../modules/consume/presentation/UseAccount';
import { ITableItem } from '../TableMap/components/tablePicker/ITableItem';
import useOperatorStore from '../../modules/operator/store/OperatorStore';
import { ICustomerFormValue } from '../TableMap/components/customerForm/ICustomerFormValue';
import UseDeviceStore from '../../modules/device/store/DeviceStore';



const UseOrderPadPage = () => {
    const { tableList, getTableList, getLocalTableList } = UseTable();
    const [device, operationMode, deviceConfig] = UseDeviceStore(state => [state.device, state.operationMode, state.deviceConfig],);
    const { openAccount } = UseAccount();
    const [selectedTable, setSelectedTable] = React.useState<ITableItem>();
    const [currentOperator] = useOperatorStore((state) => ([state.currentOperator]));

    const navigate = useNavigate();

    const onSubmitHandle = useCallback((value: IOrderPadFormValue) => {
        console.log(value);
        if (operationMode === OperationMode.TABLE) {
            if (deviceConfig?.terminal.tableMode && InputTableMode.MAP !== deviceConfig?.terminal.tableMode) {
                navigate(`/catalog/table/${value.table?.id}/${value.table?.number}`);
            } else {
                navigate(`/tableMap`);
            }
        } else if (operationMode === OperationMode.ORDER_PAD) {
            navigate(`/catalog/orderPad/${value.orderPadTag}/`);
        }
        //TODO: implementar cashless e ticket

    }, [operationMode, deviceConfig?.terminal, navigate])


    useEffect(() => {
        getLocalTableList();
        getTableList();
    }, [getLocalTableList, getTableList])


    const onSubmitOpenAccount = useCallback(async (values: ICustomerFormValue) => {
        try {
            if (selectedTable === undefined) return;
            if (currentOperator === undefined) return;
            if (device === undefined) return;
            await openAccount(values, selectedTable, currentOperator.id);
            navigate(`/catalog/table/${selectedTable.id}/${selectedTable.number}`);
        } catch (error) {
            console.log('error', error);
        }
    }, [currentOperator, device, navigate, openAccount, selectedTable])



    return ({ onSubmitHandle, getTableList, tableList, operationMode, device, openAccount, setSelectedTable, onSubmitOpenAccount, selectedTable })
}

export default UseOrderPadPage

