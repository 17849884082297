import { Button, Icon, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import styles from './Toolbar.module.scss'
import UseDimensions from '../../dimension/UseDimension';
import { useNavigate } from 'react-router-dom';
import { useKeyboard } from '../../_keyboard/KeyboardContext';
import useOperatorStore from '../../../modules/operator/store/OperatorStore';
import UseDeviceStore from '../../../modules/device/store/DeviceStore';
import packageJson from '../../../../package.json';
import { OperatorPermissions } from '../../../modules/operator/domains/models/Permissions';
import UseIsTabActive from '../../../modules/device/presentation/hooks/UseIsTabActive';
import UseSessionStore from '../../../modules/session/store/SessionStore';
import { useShallow } from 'zustand/react/shallow';
import SessionApi from '../../../services/api/session/SessionApi';
import { useUi } from '../../../modules/ui/presentation/context/UiContext';
import { UseVerifyNavigator } from '../../../modules/device/presentation/hooks/UseVerifyNavigator';
import { hideLoading, showLoading } from '../../../modules/ui/stores/UiStore';
import { usePortalComunication } from '../../../pages/catalog/hooks/usePortalComunication';
import DrawerQuery, { DrawerQueryRef } from '../../../pages/orderLog/components/drawerOrderLog/DrawerOrderLog';
import OrderLogFragment from '../../../pages/orderLog/OrderLog';
import MqttService, { client } from '../../../services/webSocketMqtt/MqttService';
import OpenSessionAlert from '../../../pages/catalog/hooks/OpenSessionAlert';
export interface IToolbarProps {
    //propertys
    logoUrl?: string,

    onClickExit?: () => void;
    onClickRfresh?: () => void;
    onClickBack?: () => void;
    refreshButton?: boolean;

}

const Toolbar: FC<IToolbarProps> = ({ logoUrl = "/assets/img/logomee.svg", onClickExit, onClickBack, onClickRfresh, refreshButton = true }) => {

    const [device, disabledSound, setDisabledSound] = UseDeviceStore(state => [state.device, state.disbledSound, state.setDisabledSound]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    usePortalComunication()


    const { verifyCurrentDeviceHandler } = UseVerifyNavigator();

    const session = UseSessionStore(useShallow(state => state.session));
    const logoutSession = UseSessionStore(state => state.logoutSession);
    const logoutOperator = useOperatorStore(state => state.logoutOperator);


    const [currentOperator, hasPermission] = useOperatorStore((state) => ([state.currentOperator, state.hasPermission]));
    const { isMobile, height, fullscreen } = UseDimensions();
    const { disabledVirtualKeyboard, setDisabledVirtualKeyboard } = useKeyboard();
    const navigate = useNavigate();
    const [online, setOnline] = useState<boolean>(navigator.onLine);

    const { isVisible } = UseIsTabActive();

    const refOrderLogDrawer = React.useRef<DrawerQueryRef>(null);

    useLayoutEffect(() => {
        verifyCurrentDeviceHandler();
    }, [verifyCurrentDeviceHandler])

    useEffect(() => {
        const connectMQTT = async () => {
            if (!client?.connected) {
                await MqttService().connect();
            }
        }
        connectMQTT();
    }, []);


    useEffect(() => {
        const onOffline = () => {
            setOnline(false);
        }
        const onOnline = () => {
            setOnline(true);
        }


        window.addEventListener('offline', () => {
            onOffline();
        });
        window.addEventListener('online', () => {
            onOnline();
        });
        return () => {
            window.removeEventListener('offline', () => {
                onOffline();
            });
            window.removeEventListener('online', () => {
                onOnline();
            });
        }
    }, [])




    const refreshHandle = useCallback(() => {
        window.location.reload()
    }, [])

    const onClickExitHandle = useCallback(async () => {
        if (!device) {
            navigate('/session');//TODO: rever comportamento
            return;
        }
        if (session) {
            try {

                const service = SessionApi();
                showLoading('@logoutSession', 'Fechando sessão');
                await logoutSession(service, device?.localId, session.id);//TODO: Colocar Loading
                await logoutOperator();
                navigate('/session');
            } finally {
                hideLoading('@logoutSession');
            }
        }
    }, [device, session, navigate, logoutSession, logoutOperator])

    const onClickKeyboardHandle = useCallback(() => {
        setDisabledVirtualKeyboard(prev => !prev);
    }, [setDisabledVirtualKeyboard])

    const onClickFullscreenHandle = useCallback(() => {
        document.documentElement.requestFullscreen();

    }, []);
    const onClickFullscreeExit = useCallback(() => {
        document.exitFullscreen();
    }, []);


    useEffect(() => {
        window.onkeydown = (e: KeyboardEvent) => {
            if (e.key === 'F4') {
                e.preventDefault();
                onClickKeyboardHandle();
            } else if (e.key === 'F5') {
                e.preventDefault();
                refreshHandle();
            } else if (e.key === 'F10') {
                e.preventDefault();
                onClickFullscreenHandle();
            }
        }
    }, [isMobile, onClickFullscreenHandle, onClickKeyboardHandle, refreshHandle])

    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (hasPermission(OperatorPermissions.CLOSE_SESSION) && currentOperator) {
            setAnchorEl(event.currentTarget);
        }
    };


    const onClickSessionClose = useCallback(() => {
        navigate('/session');
        handleClose();
    }, [navigate]);
    
    const onClickDisabledSound = useCallback(() => {
        setDisabledSound(!disabledSound);
    }, [disabledSound, setDisabledSound]);
    
    const onClickOrderLog = useCallback(() => {
        refOrderLogDrawer.current?.open();
        handleClose();
    }, []);

    return (
        <div id={styles.Toolbar} >
            <div className={styles.container} >
                <div className={styles.start}>
                    {!!onClickBack &&
                        <div className={styles.button} onClick={onClickBack}><Icon>arrow_back</Icon></div>
                    }
                    {!isMobile && <> {device?.deviceName && <div className={styles.infos}>{!isMobile && <label>PDV: </label>}{device?.deviceName}</div>} </>}
                    {!isMobile && <div className={styles.infos}>{device?.localName}</div>}
                </div>
                <Tooltip title={"Versão" + packageJson.version ?? "Meep"}>
                    <div className={styles.center}>
                        {logoUrl && <img height={30} alt="" src={logoUrl}></img>}
                    </div>
                </Tooltip>
                <div className={styles.end}>
                    {/* <Button size='small' className={styles.button} onClick={handleInstallClick}><Icon fontSize='small'>download</Icon></Button> */}
                    {
                        online ?
                            <Icon fontSize='small'>wifi</Icon>
                            :
                            <Icon fontSize='small' style={{ color: 'red' }}>wifi_off</Icon>
                    }

                    {!isMobile && height > 700 &&
                        <IconButton size='small' className={styles.button} onClick={onClickKeyboardHandle}>{!disabledVirtualKeyboard ? <Icon fontSize='small'>keyboard</Icon> : <Icon fontSize='small' style={{ opacity: 0.2 }}>keyboard_off</Icon>}</IconButton>
                    }
                    {!isMobile &&
                        <IconButton size='small' className={styles.button} onClick={onClickDisabledSound}>{!disabledSound ? <Icon fontSize='small'>volume_up</Icon> : <Icon fontSize='small' style={{ opacity: 0.2 }}>volume_off</Icon>}</IconButton>
                    }

                    {(!!onClickRfresh || refreshButton) &&
                        <IconButton size='small' className={styles.button} onClick={onClickRfresh ?? refreshHandle}><Icon fontSize='small'>refresh</Icon></IconButton>
                    }
                    {!isMobile && (
                        fullscreen ?
                            <IconButton size='small' className={styles.button} onClick={onClickFullscreeExit}><Icon fontSize='small'>fullscreen_exit</Icon></IconButton>
                            :
                            <IconButton size='small' className={styles.button} onClick={onClickFullscreenHandle}><Icon fontSize='small'>fullscreen</Icon></IconButton>
                    )}
                    {currentOperator &&
                        <>
                            <div className={styles.operatorContainer} >
                                <div /*onClick={handleClick}*/ className={styles.operatorContainer}>{!isMobile && <label>Operador: </label>}<span>{currentOperator?.name}</span></div>
                            </div>

                            {/* {
                                <Menu
                                    id="lock-menu"
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={handleClose}

                                >
                                    <MenuItem onClick={onClickSessionClose}><Icon>logout</Icon>Fechar Caixa</MenuItem>
                                </Menu>
                            } */}
                        </>
                    }

                    {currentOperator &&
                        <>
                            <IconButton onClick={handleClick}>
                                <Icon>menu</Icon>
                            </IconButton>

                            {
                                <Menu
                                    id="lock-menu"
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={onClickSessionClose}><Icon>close</Icon> Fechar Caixa</MenuItem>
                                    <MenuItem onClick={onClickOrderLog} ><Icon>history</Icon> Historico de Pedidos</MenuItem>
                                    {/* <MenuItem onClick={onClickExitHandle}><Icon>logout</Icon>Sair</MenuItem> */}
                                </Menu>
                            }
                        </>
                    }
                    {
                        currentOperator &&
                        <Button size='small' className={styles.button} onClick={onClickExitHandle}><Icon fontSize='small'>logout</Icon>Sair</Button>
                    }
                    {/* {currentSession &&
                        <IconButton size='small' className={styles.button} onClick={onClickExitHandle}><Icon fontSize='small'>logout</Icon></IconButton>
                    } */}

                </div>
                {/* <IconButton className={styles.button}>
                    {
                        _isMobile ? <Icon fontSize='small' >smartphone</Icon> : <Icon fontSize='small'>computer</Icon>
                    }
                </IconButton> */}
            </div>
            <OpenSessionAlert />
            <DrawerQuery title={'Historico de Pedidos'} queryParam={'orderLog'} ref={refOrderLogDrawer}>
                <OrderLogFragment />
            </DrawerQuery>
        </div>
    )
}
export default Toolbar
