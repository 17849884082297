// const BASE_URL = 'https://device-api.meep.cloud';
// const BASE_URL = process.env.REACT_APP_API_URL;
// export const DEVICE_API = process.env.REACT_APP_API_URL_DEVICE_CONFIG;
// export const DEVICE_API = 'http://localhost:50499';
// const BASE_URL = 'http://192.168.0.111:5000/Promptus.Meep.Server';
// const BASE_URL = 'http://localhostx/Promptus.Meep.Server';


 // prod
// export const BASE_URL = 'https://connect-api.meep.cloud';
// export const DEVICE_API = 'https://device-configuration-api.meep.cloud';
// export const PLANS_API = 'https://plans-api.meep.cloud';
// export const PORTAL_URL = 'https://connect.meep.com.br';

// // //Local
// const BASE_URL = 'https://portal-api-dev.meep.cloud';
// export const DEVICE_API = 'http://localhost:50499';
// export const PLANS_API = 'https://dev-plans-api.meep.cloud';
// export const PORTAL_URL = 'http://localhost:3000';

////dev
export const BASE_URL = 'https://portal-api-dev.meep.cloud';
export const DEVICE_API = 'https://app-dev-device-configuration-api.azurewebsites.net';
export const PLANS_API = 'https://dev-plans-api.meep.cloud';
export const PORTAL_URL = 'http://portal-dev.meep.cloud';

// export const DEVICE_API = 'http://172.16.11.57:50499';


// //LOCAL
// export const BASE_URL = 'http://192.168.0.111:5000';
// const BASE_URL = 'https://portal-api-dev.meep.cloud';
// export const DEVICE_API = 'https://app-dev-device-configuration-api.azurewebsites.net';

//env
// export const BASE_URL = process.env.REACT_APP_BASE_URL;//'https://portal-api-dev.meep.cloud';
// export const DEVICE_API = process.env.REACT_APP_DEVICE_API;//'https://app-dev-device-configuration-api.azurewebsites.net';
// export const PLANS_API = process.env.REACT_APP_PLANS_API;//'https://dev-plans-api.meep.cloud';
// export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;//'http://portal-dev.meep.cloud';
